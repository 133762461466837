

import { Injectable } from "@angular/core";
import { MQTTService } from "../telemetry/mqtt.service";
import { EMQTTStatusKeys } from "../telemetry/def";
import { GeneralCache } from "src/app/classes/app/general-cache";
import { Observable, timer } from "rxjs";
import { IDiagnosticStatus } from "./app-diagnostic.utils";
import { PromiseUtils } from "../utils/promise-utils";
import { BackgroundModeService } from "./apis/background-mode";

import { EOS } from "src/app/classes/def/app/app";
import { Diagnostic, PERMISSION_STATUS, PERMISSION, RequestAuthorizationOptions, LOCATION_AUTHORIZATION_MODE, RequestAuthorizationStatus } from 'capacitor-plugin-diagnostics/dist/esm';

import { Device } from '@capacitor/device';
import { StorageOpsService } from "./data/storage-ops";
import { ELocalAppDataKeys } from "src/app/classes/def/app/storage-flags";
import { StringUtils } from "../app/utils/string-utils";

@Injectable({
    providedIn: 'root'
})
export class AppDiagnosticService {
    diagnosticStatus: IDiagnosticStatus = {
        batteryLevel: null
    };

    constructor(
        public bgModeService: BackgroundModeService,
        public mqtt: MQTTService,
        public storageOps: StorageOpsService
    ) {
        console.log("app diagnostic service created");
        this.diagnosticInit();
        this.runCheckLoop();
    }

    diagnosticInit() {
        GeneralCache.appFlags.diagnosticStatus = this.diagnosticStatus;
    }

    runCheckLoop() {
        let timer1: Observable<number> = timer(0, 5000);
        let counterCheck: number = 0;
        let counterCheckAt: number = 6;
        timer1.subscribe(() => {
            // collect app flags
            GeneralCache.appFlags.bgModeEnabled = this.bgModeService.isBgModeEnabled();
            GeneralCache.appFlags.bgModeActiveState = this.bgModeService.isBgModeRunning();
            GeneralCache.appFlags.appPaused = GeneralCache.paused;
            GeneralCache.appFlags.os = GeneralCache.checkPlatformOS();
            GeneralCache.appFlags.isWeb = GeneralCache.isWeb;
            GeneralCache.appFlags.isPWA = GeneralCache.isPWA;

            // send app flags to mqtt
            this.mqtt.updateStatus(EMQTTStatusKeys.appFlags, GeneralCache.appFlags);
            counterCheck += 1;
            if (counterCheck >= counterCheckAt) {
                counterCheck = 0;
                // run diagnostic check
                PromiseUtils.wrapNoAction(this.checkDiagnostics(), true);
            }
        });
    }

    checkDiagnostics() {
        return new Promise<IDiagnosticStatus>(async (resolve) => {
            if (GeneralCache.os !== EOS.browser) {
                let getBatteryLevel: Promise<number> = new Promise((resolve) => {
                    this.getCurrentBatteryLevel().then((level: number) => {
                        resolve(level);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(null);
                    });
                });
                this.diagnosticStatus.batteryLevel = await getBatteryLevel;
                GeneralCache.appFlags.diagnosticStatus = this.diagnosticStatus;
            }
            resolve(this.diagnosticStatus);
        });
    }

    /**
     * get device info (mobile)
     * generate uids
     * check browser agent
     * @returns 
     */
    getDeviceInfo() {
        return new Promise(async (resolve) => {
            try {
                if (GeneralCache.os !== EOS.browser) {
                    const info = await Device.getInfo();
                    GeneralCache.deviceFlags.model = info.model;
                    console.log("get device info: ", info);
                    resolve(info);
                }

                let uid: string = await this.storageOps.getLocalDataKeyResolve(ELocalAppDataKeys.deviceUid);
                if (uid == null) {
                    uid = StringUtils.generateRandomStringCode(12, true, true);
                    this.storageOps.setStorageFlagNoAction({
                        flag: ELocalAppDataKeys.deviceUid,
                        value: uid
                    });
                }

                GeneralCache.deviceFlags.uid = uid;
                GeneralCache.deviceFlags.browser = navigator.userAgent;
                resolve(true);
            } catch (err) {
                resolve(null);
            }
        });
    }

    getDiagnosticsCache() {
        return this.diagnosticStatus;
    }

    // GENERIC DIAGNOSTICS

    async requestAuthorization(options: RequestAuthorizationOptions): Promise<void> {
        await Diagnostic.requestAuthorization(options);
    }

    async getCurrentBatteryLevel(): Promise<number> {
        const result = await Diagnostic.getCurrentBatteryLevel();
        return result.level;
    }

    // SPEC/FEATURE DIAGNOSTICS

    // CHECK
    async isGpsLocationEnabled(): Promise<boolean> {
        const result = await Diagnostic.isGpsLocationEnabled();
        console.log('GPS Location is enabled:', result);
        return result.enabled;
    }

    async isLocationAuthorized(): Promise<RequestAuthorizationStatus> {
        const result = await Diagnostic.isFeatureAuthorized({ feature: "location" });
        return result;
    }

    async isLocationAlwaysAuthorized(): Promise<RequestAuthorizationStatus> {
        const result = await Diagnostic.isFeatureAuthorized({ feature: "locationAlways" });
        return result;
    }

    async isMicrophoneAuthorized(): Promise<RequestAuthorizationStatus> {
        const result = await Diagnostic.isFeatureAuthorized({ feature: "microphone" });
        return result;
    }

    async isCameraAuthorized(): Promise<RequestAuthorizationStatus> {
        const result = await Diagnostic.isFeatureAuthorized({ feature: "camera" });
        return result;
    }

    // REQUEST
    async requestCameraAuthorizationWizard() {
        const isAuthorized = await this.isCameraAuthorized();
        if (!isAuthorized) {
            return;
        }
        console.log('Camera is authorized:', isAuthorized);
        if (!isAuthorized.authorized) {
            return await this.requestAuthorization({ feature: 'camera' });
        }
    }

    async requestMicrophoneAuthorizationWizard() {
        const isAuthorized = await this.isMicrophoneAuthorized();
        if (!isAuthorized) {
            return;
        }
        console.log('Microphone is authorized:', isAuthorized);
        if (!isAuthorized.authorized) {
            await this.requestAuthorization({ feature: 'microphone' });
        }
    }

    async requestLocationAuthorization() {
        const isAuthorized = await this.isLocationAuthorized();
        if (!isAuthorized) {
            return;
        }
        console.log('Location is authorized:', isAuthorized);
        if (!isAuthorized.authorized) {
            return await this.requestAuthorization({ feature: 'location', mode: LOCATION_AUTHORIZATION_MODE.WHEN_IN_USE });
        }
    }

    async requestLocationAlwaysAuthorization() {
        const isAuthorized = await this.isLocationAlwaysAuthorized();
        if (!isAuthorized) {
            return;
        }
        console.log('Location is authorized:', isAuthorized);
        if (!isAuthorized.authorized) {
            return await this.requestAuthorization({ feature: 'location', mode: LOCATION_AUTHORIZATION_MODE.ALWAYS });
        }
    }
}




