
import { Injectable } from "@angular/core";
import { BackgroundGeolocation } from "@ionic-native/background-geolocation/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { EAlertButtonCodes } from "src/app/classes/def/app/ui";
import { LocationAccuracy } from "@ionic-native/location-accuracy/ngx";
import { IDescriptionFrameParams, EDescriptionViewStyle } from "src/app/modals/generic/modals/description-frame/description-frame.component";
import { GeneralCache } from "src/app/classes/app/general-cache";
import { EOS } from "src/app/classes/def/app/app";
import { ETutorialEntries } from "src/app/classes/def/app/tutorials";
import { ENativeSettingsContext } from "./permission.utils";
import { IPlatformFlags } from "src/app/classes/def/app/platform";
import { TutorialsService } from "../../app/modules/minor/tutorials";
import { SleepUtils } from "../../utils/sleep-utils";
import { BackgroundModeWatchService } from "../apis/background-mode-watch";
import { SettingsManagerService } from "../settings-manager";
import { UiExtensionService } from "../ui/ui-extension";
import { AppDiagnosticService } from "../app-diagnostic.service";
import { PERMISSION_STATUS, RequestAuthorizationStatus } from 'capacitor-plugin-diagnostics/dist/esm';
import { TelemetryEventsService } from "../../telemetry/events.service";
import { PromiseUtils } from "../../utils/promise-utils";
import { LocationMonitorService } from "../../map/location-monitor";
import { ILatLng } from "src/app/classes/def/map/coords";
import { EGPSTimeout } from "src/app/classes/def/map/geolocation";
// import { RequestAuthorizationStatus } from "capacitor-plugin-diagnostics/dist/esm";

export interface ICheckLocationPermission {
    status: boolean;
    code: any;
}

@Injectable({
    providedIn: 'root'
})
export class LocationPermissionManagerService {
    platform: IPlatformFlags = {} as IPlatformFlags;
    alreadyRequested: boolean = false;
    locationWatchId: number = null;
    enableContinuousLocationPermissionsMonitoring: boolean = true;

    constructor(
        public backgroundGeolocation: BackgroundGeolocation,
        public androidPermissions: AndroidPermissions,
        public openNativeSettings: OpenNativeSettings,
        public locationAccuracy: LocationAccuracy,
        public diagnostic: Diagnostic,
        public appDiagnostic: AppDiagnosticService,
        public settings: SettingsManagerService,
        public uiext: UiExtensionService,
        public bgmWatch: BackgroundModeWatchService,
        public tutorials: TutorialsService,
        public telemetryEvents: TelemetryEventsService,
        public locationMonitor: LocationMonitorService
    ) {
        console.log("location permission manager service created");
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded(SettingsManagerService.settings.platformFlags);
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    onPlatformLoaded(platform: IPlatformFlags) {
        console.log("location manager set platform: ", platform);
        this.platform = platform;
    }

    requestBackgroundGeolocationIOS() {
        let promise = new Promise((resolve) => {
            console.log("requesting background geolocation access");
            if (GeneralCache.os !== EOS.ios) {
                resolve(false);
                return;
            }
            // console.log("requesting background geolocation access (iOS)");
            this.backgroundGeolocation.start().then(() => {
                this.backgroundGeolocation.stop().then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }

    updateStatusFlags(locationStatus: string) {
        GeneralCache.appFlags.locationPermissionsGranted = [PERMISSION_STATUS.GRANTED, PERMISSION_STATUS.GRANTED_WHEN_IN_USE].indexOf(locationStatus) !== -1;
        GeneralCache.appFlags.locationPermissionsAlwaysGranted = locationStatus === PERMISSION_STATUS.GRANTED;
    }

    checkBackgroundLocationPermission() {
        return new Promise<ICheckLocationPermission>(async (resolve, reject) => {
            try {
                if (this.platform.ANDROID) {
                    let checkLocationAuth: RequestAuthorizationStatus = await this.appDiagnostic.isLocationAuthorized();
                    let status: boolean = checkLocationAuth.authorized;
                    let locationStatus: string = checkLocationAuth.state;
                    console.log("location authorization status: ", checkLocationAuth);
                    if (GeneralCache.enforceBackgroundLocationPermissionRequestOnAndroid && ([PERMISSION_STATUS.GRANTED].indexOf(locationStatus) === -1)) {
                        console.log("Permission not granted (always)");
                        status = false;
                    }
                    if (!GeneralCache.enforceBackgroundLocationPermissionRequestOnAndroid && ([PERMISSION_STATUS.GRANTED, PERMISSION_STATUS.GRANTED_WHEN_IN_USE].indexOf(locationStatus) === -1)) {
                        console.log("Permission not granted (when in use)");
                        status = false;
                    }
                    this.updateStatusFlags(locationStatus);
                    let res: ICheckLocationPermission = {
                        status: status,
                        code: locationStatus
                    };
                    resolve(res);
                } else {
                    let checkLocationAuth: RequestAuthorizationStatus = await this.appDiagnostic.isLocationAuthorized();
                    let status: boolean = checkLocationAuth.authorized;
                    let locationStatus: string = checkLocationAuth.state;
                    console.log("location authorization status: ", checkLocationAuth);
                    if (locationStatus !== PERMISSION_STATUS.GRANTED) {
                        console.log("Permission not granted");
                        status = false;
                    }
                    this.updateStatusFlags(locationStatus);
                    if (locationStatus === PERMISSION_STATUS.DENIED_ALWAYS) {
                        console.log("Permission denied always. Throwing exception.");
                        reject(new Error("Permission denied by user. You may only unlock the permissions from Settings app."));
                        return;
                    }
                    let res: ICheckLocationPermission = {
                        status: status,
                        code: locationStatus
                    };
                    resolve(res);
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * check location permissions granted incl. background location
     * retry until granted or user declined
     * @returns 
     */
    checkLocationSettingsEnabled(reset: boolean) {
        if (this.platform.ANDROID) {
            return this.checkLocationSettingsEnabledAndroid(reset);
        } else {
            return this.checkLocationSettingsEnabledIOS(reset);
        }
    }

    async requireNativePermissionChange(type: string = ENativeSettingsContext.app) {
        await this.openNativeSettings.open(type);
        await this.bgmWatch.waitDefaultBgmWatch(false);
    }

    async checkNativeLocationSettings(): Promise<ICheckLocationPermission> {
        // redirect to app settings to enable location
        if (this.platform.ANDROID) {
            await this.requireNativePermissionChange(ENativeSettingsContext.app);
        } else {
            await this.requireNativePermissionChange(ENativeSettingsContext.app);
        }
        console.log("location authorization request complete");
        let granted = await this.checkBackgroundLocationPermission();
        console.log("permission check after settings: ", granted);
        return granted;
    }

    /**
     * check location permissions granted incl. background location
     * retry until granted or user declined
     * @returns 
     */
    private checkLocationSettingsEnabledAndroid(reset: boolean) {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                let first: boolean = true;
                let userDismiss: boolean = false;
                let checkPermission = async () => {
                    let granted: boolean = false;
                    let checkStatus: ICheckLocationPermission;
                    try {
                        checkStatus = await this.checkBackgroundLocationPermission();
                        console.log("permission check: ", granted);
                        granted = checkStatus.status;
                        if (!granted || reset) {
                            console.log("location not authorized");
                            // redirect to android settings
                            let res: number = first ? await this.showProminentDisclosure(ETutorialEntries.prominentDisclosure) : EAlertButtonCodes.ok;
                            console.log(res);

                            // For Android 11+ / API 30+
                            // You must request mode=WHEN_IN_USE before requesting mode=ALWAYS
                            let locationStatus: any = checkStatus.code;
                            console.log("location authorization status (2): ", locationStatus);

                            if (this.alreadyRequested || (locationStatus === PERMISSION_STATUS.GRANTED)) {
                                // request location not allowed in this context, this must be done via settings app
                                checkStatus = await this.checkNativeLocationSettings();
                                granted = checkStatus.status;
                                console.log("check granted: ", granted);
                                return granted;
                            }

                            let data: any;
                            data = await this.appDiagnostic.requestLocationAuthorization();
                            console.log("request location authorization - WHEN_IN_USE: ", data);
                            let checkLocationAuth: RequestAuthorizationStatus = await this.appDiagnostic.isLocationAuthorized();
                            locationStatus = checkLocationAuth.state;
                            console.log("location authorization status (3): ", checkLocationAuth);
                            this.alreadyRequested = true;
                            // if user denied, don't request again (to allow for the promise to resolve)
                            if ([PERMISSION_STATUS.DENIED_ALWAYS, PERMISSION_STATUS.DENIED_ONCE].indexOf(locationStatus) !== -1) {
                                return false;
                            }
                            if (GeneralCache.enforceBackgroundLocationPermissionRequestOnAndroid) {
                                data = await this.appDiagnostic.requestLocationAlwaysAuthorization();
                                console.log("request location authorization - ALWAYS: ", data);
                            }

                            checkStatus = await this.checkBackgroundLocationPermission();
                            granted = checkStatus.status;
                            console.log("check granted: ", granted);
                            this.alreadyRequested = true;
                        } else {
                            console.log("location already authorized");
                        }
                    } catch (err) {
                        console.log("location not authorized (denied always?)");
                        let res: number = first ? await this.showProminentDisclosure(ETutorialEntries.prominentDisclosure) : EAlertButtonCodes.ok;
                        console.log(res);
                        // redirect to app settings to enable location always
                        checkStatus = await this.checkNativeLocationSettings();
                        granted = checkStatus.status;
                    }
                    return granted;
                };
                let retryFn = async () => {
                    try {
                        let granted: boolean = await checkPermission();
                        first = false;
                        if (granted) {
                            resolve(true);
                        } else {
                            // not granted
                            if (userDismiss) {
                                resolve(false);
                            } else {
                                // retry fn, show prominent disclosure again before retry
                                let res: number = await this.showProminentDisclosure(ETutorialEntries.prominentDisclosure);
                                if (res === EAlertButtonCodes.ok) {
                                    retryFn();
                                } else {
                                    resolve(false);
                                }
                            }
                        }
                    } catch (e) {
                        resolve(false);
                    }
                }
                retryFn();
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * check location permissions granted incl. background location
     * retry until granted or user declined
     * @returns 
     */
    private checkLocationSettingsEnabledIOS(reset: boolean) {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                let first: boolean = true;
                let userDismiss: boolean = false;
                let checkPermission = async () => {
                    let granted: boolean = false;
                    let checkStatus: ICheckLocationPermission;
                    try {
                        checkStatus = await this.checkBackgroundLocationPermission();
                        console.log("permission check: ", granted);
                        granted = checkStatus.status;
                        if (!granted || reset) {
                            console.log("location not authorized");
                            // redirect to android settings
                            let res: number = first ? await this.showProminentDisclosure(ETutorialEntries.prominentDisclosure) : EAlertButtonCodes.ok;
                            console.log(res);

                            // For Android 11+ / API 30+
                            // You must request mode=WHEN_IN_USE before requesting mode=ALWAYS
                            let locationStatus: any = checkStatus.code;
                            console.log("location authorization status (2): ", locationStatus);

                            if (this.alreadyRequested || (locationStatus === PERMISSION_STATUS.GRANTED_WHEN_IN_USE)) {
                                // request location not allowed in this context, this must be done via settings app
                                checkStatus = await this.checkNativeLocationSettings();
                                granted = checkStatus.status;
                                console.log("check granted: ", granted);
                                return granted;
                            }

                            let data: any;
                            data = await this.appDiagnostic.requestLocationAuthorization();
                            console.log("request location authorization - WHEN_IN_USE: ", data);
                            let checkLocationAuth: RequestAuthorizationStatus = await this.appDiagnostic.isLocationAuthorized();
                            locationStatus = checkLocationAuth.state;
                            console.log("location authorization status (3): ", checkLocationAuth);
                            this.alreadyRequested = true;
                            // if user denied, don't request again (to allow for the promise to resolve)
                            if ([PERMISSION_STATUS.DENIED_ALWAYS, PERMISSION_STATUS.DENIED_ONCE].indexOf(locationStatus) !== -1) {
                                return false;
                            }
                            data = await this.appDiagnostic.requestLocationAlwaysAuthorization();
                            console.log("request location authorization - ALWAYS: ", data);
                            checkStatus = await this.checkBackgroundLocationPermission();
                            granted = checkStatus.status;
                            console.log("check granted: ", granted);
                            this.alreadyRequested = true;
                        } else {
                            console.log("location already authorized");
                        }
                    } catch (err) {
                        console.log("location not authorized (denied always?)");
                        let res: number = first ? await this.showProminentDisclosure(ETutorialEntries.prominentDisclosure) : EAlertButtonCodes.ok;
                        console.log(res);
                        // redirect to app settings to enable location always
                        checkStatus = await this.checkNativeLocationSettings();
                        granted = checkStatus.status;
                    }
                    return granted;
                };
                let retryFn = async () => {
                    try {
                        let granted: boolean = await checkPermission();
                        first = false;
                        if (granted) {
                            resolve(true);
                        } else {
                            // not granted
                            if (userDismiss) {
                                resolve(false);
                            } else {
                                // retry fn, show prominent disclosure again before retry
                                let res: number = await this.showProminentDisclosure(ETutorialEntries.prominentDisclosure);
                                if (res === EAlertButtonCodes.ok) {
                                    retryFn();
                                } else {
                                    resolve(false);
                                }
                            }
                        }
                    } catch (e) {
                        resolve(false);
                    }
                }
                retryFn();
            } catch (err) {
                console.log("location authorization exception");
                reject(err);
            }
        });
    }

    /**
    * check GPS/high accuracy location permissions
    * request permissions if not already granted
    * (this is to turn on the location, not for granting permissions)
    */
    requestLocationEnabledHighAccuracy() {
        return new Promise<boolean>(async (resolve, reject) => {
            // ANDROID ONLY
            try {
                if (this.platform.ANDROID) {
                    let status: boolean = await this.appDiagnostic.isGpsLocationEnabled();
                    if (status === false) {
                        console.log("gps location not enabled");
                        let canRequest: boolean = await this.locationAccuracy.canRequest();
                        if (canRequest) {
                            console.log("request enable gps location");
                            await this.locationAccuracy.request(this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY);
                            console.log("gps location enable done");
                            resolve(true);
                        } else {
                            reject(new Error("location request denied"));
                        }
                    } else {
                        resolve(true);
                    }
                } else {
                    resolve(false);
                }
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * Start continuous tracking to confirm "Allow While Using" was selected
     */
    startContinuousLocationTracking() {
        console.log("Starting continuous location tracking...");
        if (this.locationWatchId == null) {

            let opts: PositionOptions = {
                enableHighAccuracy: false,
                timeout: EGPSTimeout.gps,
                maximumAge: EGPSTimeout.maxAge // was 0
            };

            this.locationWatchId = navigator.geolocation.watchPosition(
                (position) => {
                    console.log("Tracking location permissions updated location:", position?.coords);
                },
                (error) => {
                    console.log("Tracking location permissions error:", error);
                    if (error.code === error.PERMISSION_DENIED) {
                        console.log("Tracking stopped. User likely selected 'Allow Once'.");
                        PromiseUtils.wrapNoAction(this.telemetryEvents.sendPlainNotification(null, "[LP/App] location permissions updated", [["granted", true], ["state", "tracking stopped / allowed once?"]], false), true);
                        PromiseUtils.wrapNoAction(this.promptForPersistentPermission(), true);
                    }
                }, opts
            );
        } else {
            console.warn("location permissions tracking already enabled");
        }
        return this.locationWatchId; // Store this ID if you want to stop tracking later
    }

    stopContinuousLocationTracking() {
        if (this.locationWatchId == null) {
            return;
        }
        navigator.geolocation.clearWatch(this.locationWatchId);
        this.locationWatchId = null;
    }

    /**
     * If tracking stops, prompt the user to allow "While Using"
     */
    async promptForPersistentPermission() {
        await this.showProminentDisclosure(ETutorialEntries.prominentDisclosureWebAllowedOnce);
        await this.requestBrowserLocationPermissionsUntilGranted(); // Restart the permission request
    }


    checkLocationPermissionsTryGetLocationBrowser() {
        return new Promise<boolean>((resolve) => {
            let opts: PositionOptions = {
                enableHighAccuracy: false,
                maximumAge: 0,
                timeout: 30000
            };
            // Request permission explicitly
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log("User granted permission result:", position?.coords);
                    let loc: ILatLng = { lat: 0, lng: 0 };
                    if (position?.coords) {
                        loc.lat = position?.coords.latitude;
                        loc.lng = position?.coords.longitude;
                        this.locationMonitor.setRealLocationCache(loc);
                    }
                    resolve(true);
                },
                (error) => {
                    console.log("User denied permission:", error);
                    resolve(false);
                },
                opts
            );
        });
    }

    /**
     * query browser location permission
     * if granted, cool, but still watch location to catch potential permissions disabled in the future (or due to user selected "allow once")
     * if not granted, show popup disclosure, then request location which will trigger browser permissions popup again
     * do this until the location is successfully retrieved (showing that permissions were allowed at least once)
     * then start watching location to catch potential permissions disabled in the future (or due to user selected "allow once")
     * @returns 
     */
    async requestBrowserLocationPermissionsUntilGranted() {
        let granted = false;
        let permissionStatus = await navigator.permissions.query({ name: 'geolocation' });

        console.log("Initial permission state:", permissionStatus.state);

        if (permissionStatus.state === "granted") {
            console.log("User already granted 'Allow While Using' or 'Allow Once'");
            if (this.enableContinuousLocationPermissionsMonitoring) {
                this.startContinuousLocationTracking();
            }
            granted = true;
            return;
        }

        // Attach listener to detect permission changes
        // permissionStatus.onchange = () => {
        //     console.log("Permission changed:", permissionStatus.state);
        //     if (permissionStatus.state === "granted") {
        //         console.log("User granted / 'Allow While Using'");
        //         granted = true; // Mark as granted
        //     }
        // };

        let retries = 0;
        const MAX_RETRIES = 5; // Prevent infinite loop

        // Retry loop until the user grants "Allow While Using"
        while (!granted) {
            let state: string = permissionStatus.state;
            console.log("setup permissions web granted 1: " + granted + " state: " + state);
            console.log("Requesting location...");
            granted = await this.checkLocationPermissionsTryGetLocationBrowser();

            // Re-query permission state again after request
            permissionStatus = await navigator.permissions.query({ name: 'geolocation' }); // this doesn't work on iOS (always returns "prompt")
            state = permissionStatus.state;
            console.log("setup permissions web granted 2: " + granted + " state: " + state);

            if (granted || (state === "granted")) {
                granted = true;
                state = "granted";
                if (this.enableContinuousLocationPermissionsMonitoring) {
                    this.stopContinuousLocationTracking();
                    this.startContinuousLocationTracking();
                }
                // checking again at this point is ineffective (permissions might be granted once and keep working for some time, or until refresh, but can't know for sure / browser behavior)
                // so there needs to be a continuous location watch
                break; // stop retrying
            }

            if (!granted || (state === "prompt")) {
                await this.showProminentDisclosure(ETutorialEntries.prominentDisclosureWeb);
                PromiseUtils.wrapNoAction(this.telemetryEvents.sendPlainNotification(null, "[LP/App] location permissions updated", [["granted", false], ["state", "denied"]], false), true);
                // keep retrying
            }

            if (!granted || (state === "denied")) {
                await this.showProminentDisclosure(ETutorialEntries.prominentDisclosureWebDenied);
                PromiseUtils.wrapNoAction(this.telemetryEvents.sendPlainNotification(null, "[LP/App] location permissions updated", [["granted", false], ["state", "denied"]], false), true);
                break; // Stop retrying if user completely blocks location
            }

            retries++;
            if (retries >= MAX_RETRIES) {
                console.warn("Max retries reached. Stopping permission requests.");
                break;
            }

            await SleepUtils.sleep(2000); // Wait before retrying
        }
    }

    /**
    * check location permissions
    * request permissions if not already granted
    */
    setupLocationService(reset: boolean): Promise<boolean> {
        console.log("init permissions");
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            try {
                if (this.platform.WEB) {
                    await this.requestBrowserLocationPermissionsUntilGranted();
                    resolve(true);
                    return;
                }
                await this.checkLocationSettingsEnabled(reset);
                console.log("location authorization done");
                await SleepUtils.sleep(500);
                await this.requestLocationEnabledHighAccuracy();
                console.log("location accuracy request done");
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    showProminentDisclosure(loaderCode: number) {
        let params: IDescriptionFrameParams = {
            loaderCode: loaderCode,
            fromCache: true,
            title: "Allow in-app location",
            description: null,
            mode: EDescriptionViewStyle.withOk,
            photoUrl: null
        };
        return this.tutorials.showTutorialResolve("Allow in-app location", loaderCode, null, params, true);
    }
}


