
import { Injectable } from '@angular/core';

import {
    IMqttMessage,
    IMqttServiceOptions,
    MqttService,
    IPublishOptions,
} from 'ngx-mqtt';
import { IClientSubscribeOptions } from 'mqtt-browser';
import { StringUtils } from '../app/utils/string-utils';
import { EMQTTCommandCodes, EMQTTTopics, IMQTTChatMessage, IMQTTCommand, IMQTTLog, IMQTTStatusCache, IMQTTStatusCacheElement } from './def';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { ISubMultiplex } from 'src/app/classes/def/mp/subs';
import { BehaviorSubject } from 'rxjs';
import { WaitUtils } from '../utils/wait-utils';

@Injectable({
    providedIn: 'root'
})
export class MQTTService {

    private subscription: ISubMultiplex = {};

    connection: IMqttServiceOptions = {
        hostname: 'mq.leplace-api.com',
        // hostname: 'broker.emqx.io',
        port: 9101,
        // port: 9001,
        // port: 8084,
        // path: '/mqtt',
        path: '',
        clean: true, // Retain session
        connectTimeout: 20000, // Timeout period
        reconnectPeriod: 1000, // Reconnect period
        // Authentication information
        clientId: 'lpw',
        username: 'pi',
        password: 'raspberry',
        // protocol: 'ws'
        protocol: 'wss'
    };
    subscribeContext = {
        topic: 'lp/cmd',
        qos: 0,
    };
    publishContext = {
        topic: 'lp/status/dist',
        qos: 0,
        payload: null,
    };
    receiveNews = '';
    qosList = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
    ];
    client: MqttService | undefined;
    isConnection = false;
    subscribeSuccess = false;

    status: IMQTTStatusCache = {
        userId: null,
        location: null,
        mapCenter: null,
        userMarker: null,
        droneMarker: null,
        appFlags: null,
        inGame: false,
        isTester: false,
        ts: null
    };

    watchTelemetryCmd: BehaviorSubject<boolean> = null;
    watchConnection: BehaviorSubject<boolean> = null;
    watchInboundChat: BehaviorSubject<IMQTTChatMessage> = null;

    constructor(
        private _mqttService: MqttService
    ) {
        console.log("MQTT service created");
        this.client = this._mqttService;
        this.watchTelemetryCmd = new BehaviorSubject<boolean>(null);
        this.watchConnection = new BehaviorSubject<boolean>(null);
        this.watchInboundChat = new BehaviorSubject<IMQTTChatMessage>(null);
    }

    // Create a connection
    createConnection() {
        // Connection string, which allows the protocol to specify the connection method to be used
        // ws Unencrypted WebSocket connection
        // wss Encrypted WebSocket connection
        // mqtt Unencrypted TCP connection
        // mqtts Encrypted TCP connection
        if (this.isConnection) {
            console.warn("connection already established");
            return;
        }
        try {
            this.connection.clientId = "lpw_" + StringUtils.generateRandomStringCode(10, false, true);
            this.client?.connect(this.connection as IMqttServiceOptions);
        } catch (error) {
            console.log('mqtt.connect error', error);
        }
        this.client?.onConnect.subscribe(() => {
            this.isConnection = true;
            this.watchConnection.next(true);
            console.log('Connection succeeded!');
        });
        this.client?.onError.subscribe((error: any) => {
            this.isConnection = false;
            this.watchConnection.next(false);
            console.log('Connection failed', error);
        });
        this.client?.onMessage.subscribe((packet: any) => {
            this.receiveNews = this.receiveNews.concat(packet.payload.toString());
            console.log(`Received message ${packet.payload.toString()} from topic ${packet.topic}`);
        });
    }

    getWatchTelemetryCommands() {
        this.watchTelemetryCmd.next(null);
        return this.watchTelemetryCmd;
    }

    getWatchInboundChat() {
        this.watchInboundChat.next(null);
        return this.watchInboundChat;
    }

    waitForConnection() {
        return new Promise((resolve) => {
            console.log("mqtt wait for connection");
            WaitUtils.waitFlagResolve(this.isConnection, this.watchConnection, [true], null).then((ok: boolean) => {
                if (ok != null) {
                    console.log("connection detected: ", ok);
                    resolve(ok);
                }
            });
        });
    }

    subscribeToCommands() {
        this.waitForConnection().then(() => {
            this.doSubscribe(EMQTTTopics.cmd + "/user/" + GeneralCache.userId, (message: string) => {
                try {
                    let cmd: IMQTTCommand = JSON.parse(message);
                    console.log("message parsed: ", cmd);
                    if (cmd != null) {
                        switch (cmd.code) {
                            case EMQTTCommandCodes.activateTelemetry:
                                console.log("telemetry request detected");
                                if (cmd.value) {
                                    this.watchTelemetryCmd.next(true);
                                } else {
                                    this.watchTelemetryCmd.next(false);
                                }
                                break;
                            case EMQTTCommandCodes.inboundChat:
                                if (cmd.data != null) {
                                    let message: IMQTTChatMessage = cmd.data;
                                    message.ext = true;
                                    message.username = "operator";
                                    this.watchInboundChat.next(message);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            });
        });
    }

    doSubscribe(topic: string, onMessage: (message: string) => any) {
        console.log("subscribing to mqtt topic: " + topic);
        if (!(this.client && this.isConnection)) {
            console.warn("mqtt client not connected");
            return;
        }
        if (!this.subscription[topic]) {
            this.subscription[topic] = this.client.observe(topic, { qos: 0 } as IClientSubscribeOptions).subscribe((message: IMqttMessage) => {
                if (message != null) {
                    // console.log('Subscribe to topics res', message.payload.toString());
                    onMessage(message.payload.toString());
                }
            }, (err: Error) => {
                console.error(err);
            });
        } else {
            console.warn("already subscribed to mqtt topic: " + topic);
        }
    }

    unsubscribeAll() {
        this.subscription = ResourceManager.clearSubObj(this.subscription);
    }


    updateStatus(key: string, data: any) {
        try {
            // check elapsed timestamp publish
            let ts: Date = new Date();
            this.updateStatusCore(key, data, ts, true);
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * 
     * @param key 
     * @param data this should be object type
     * @param ts 
     * @param publish 
     * @returns 
     */
    updateStatusCore(key: string, data: IMQTTStatusCacheElement, ts: Date, publish: boolean) {
        if (ts == null) {
            ts = new Date();
        }
        try {
            // data should be object type
            if (data != null) {
                data._status = true;
                data._ts = ts;
            } else {
                data = {
                    _status: false,
                    _ts: ts
                };
            }
        } catch {
            // data is of primary type instead of object
            data = {
                _status: false,
                _ts: ts,
                _data: data
            };
        }
        this.status[key] = data;
        if (GeneralCache.userId == null || GeneralCache.userId === 0) {
            console.warn("user id not set");
            return;
        }
        this.status.userId = GeneralCache.userId;
        this.status.isTester = GeneralCache.canBeTester;
        if (publish) {
            this.checkPublishStatus(ts);
        }
    }

    checkPublishStatus(ts: Date) {
        if (this.status.ts == null) {
            this.status.ts = ts;
            this.publishStatus();
        } else {
            if (ts.getTime() - this.status.ts.getTime() >= 1000) {
                this.status.ts = ts;
                this.publishStatus();
            }
        }
    }

    publishStatus() {
        let payload = JSON.stringify(this.status);
        // this.doPublish(EMQTTTopics.status, payload);
        this.doPublish(EMQTTTopics.status + "/user/" + GeneralCache.userId, payload);
    }

    publishLog(category: string, action: string, label: string) {
        let ts: Date = new Date();
        let log: IMQTTLog = {
            category: category,
            action: action,
            label: label,
            ts: ts
        };
        return this.doPublish(EMQTTTopics.console + "/user/" + GeneralCache.userId, JSON.stringify(log));
    }

    doPublish(topic: string, payload: string): Promise<boolean> {
        return new Promise((resolve) => {
            if (!(this.client && this.isConnection)) {
                console.warn("mqtt client not connected");
                resolve(false);
                return;
            }
            this.client.publish(topic, payload, { qos: 0 } as IPublishOptions).subscribe(() => {
                resolve(true);
            }, (err) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    destroyConnection() {
        try {
            this.client?.disconnect(true);
            this.isConnection = false;
            console.log('Successfully disconnected!');
        } catch (error: any) {
            console.log('Disconnect failed', error.toString());
        }
    }
}
