import { GeolocationOptions } from '@ionic-native/geolocation/ngx';
// import { BackgroundGeolocationConfig } from '@ionic-native/background-geolocation/ngx';

export enum EGeolocationModes {
    none = 0,
    native = 1,

    background = 3,
    cordova = 4,

    nativePolling = 5,
    cordovaPolling = 6
}

export enum EMeasurementSystem {
    metric = "metric",
    imperial = "imperial"
}

export enum ESpeedMode {
    speed = 0,
    position = 1
}


export enum EGPSTimeout {
    gps = 15000,
    watchdog = 30000,
    slowUpdate = 5000,
    slowUpdateReset = 10000,

    maxAge = 60000
}



export enum ELocationTimeoutEvent {
    resetConnectionReacquired = -2,
    clearWatchdog = -1,
    timeout = 0,
    timeoutWatchdog = 1,
    timeoutNotFound = 2,
    timeoutSlowUpdate = 3,
    timeoutSlowUpdateWarning = 4   
}

export interface IGeolocationOptionsSetup {
    navigator: PositionOptions;
    cordova: GeolocationOptions;
    // background: BackgroundGeolocationConfig;
    background: any;
    web: PositionOptions;
}

