
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';

export type TEmailEventContent = Array<Array<any>>;

class IApiSendEmailNotificationAdmin {
    eventKey: string;
    force: boolean;
    title: string;
    content: TEmailEventContent;
}

@Injectable({
    providedIn: 'root'
})
export class TelemetryEventsService {
    constructor(
        public http: HttpClient,
        public generic: GenericDataService,
    ) {
        console.log("telemetry events data service created");
    }

    sendPlainNotification(key: string, title: string, content: TEmailEventContent, force: boolean) {
        let promise = new Promise((resolve, reject) => {
            let body: IApiSendEmailNotificationAdmin = {
                eventKey: key,
                title: title,
                force: force,
                content: content
            };
            this.generic.genericPostStandardWData("/notifications/email/send-plain-notification", body).then((resp: any) => {               
                resolve(resp);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}



