import { Injectable } from "@angular/core";
import { Messages } from 'src/app/classes/def/app/messages';
import { SettingsManagerService } from '../settings-manager';
import { BackgroundMode, BackgroundModeConfiguration } from '@ionic-native/background-mode/ngx';
import { GeneralCache } from "src/app/classes/app/general-cache";
import { EOS } from "src/app/classes/def/app/app";
import { BackgroundModeAndroidService } from "./background-mode-android";

@Injectable({
    providedIn: 'root'
})
export class BackgroundModeService {

    isEnabled: boolean = false;
    isActivated: boolean = false;
    initialized: boolean = false;
    useAndroidForegroundService: boolean = false;

    constructor(
        public backgroundMode: BackgroundMode,
        public backgroundModeAndroidProvider: BackgroundModeAndroidService
    ) {
        console.log("background mode service created");
    }

    /**
     * init config
     */
    async init() {
        console.log("init background mode");
        if (this.useAndroidForegroundService && GeneralCache.os === EOS.android) {
            return this.backgroundModeAndroidProvider.init();
        }
        let useExternalNotification: boolean = SettingsManagerService.settings.app.settings.useExternalBgModeNotification.value;
        let hiddenNotification: boolean = SettingsManagerService.settings.app.settings.useHiddenBgModeNotification.value;

        let config: BackgroundModeConfiguration = {
            title: Messages.notification.backgroundMode.after.msg,
            text: Messages.notification.backgroundMode.after.sub,
            ticker: Messages.notification.backgroundMode.after.msg,
            bigText: true,

            silent: useExternalNotification ? true : false,
            hidden: hiddenNotification ? true : false

            // silent: false,
            // hidden: false

            // silent: true, hidden: false // does not keep the app running!

            // silent: true, // prevent crash on android 9 workaround
            // hidden: true

        };

        this.backgroundMode.setDefaults(config);
        this.backgroundMode.configure(config);

        if (!this.initialized) {
            this.backgroundMode.on('activate').subscribe(() => {
                console.log("background mode activated");
                this.backgroundMode.configure(config);
                // this.backgroundMode.disableWebViewOptimizations();
                this.isActivated = true;
            });

            this.backgroundMode.on('deactivate').subscribe(() => {
                console.log("background mode deactivated");
                this.isActivated = false;
            });
        }

        this.initialized = true;
    }


    isBgModeRunning() {
        if (this.useAndroidForegroundService && GeneralCache.os === EOS.android) {
            return this.backgroundModeAndroidProvider.isBgModeRunning();
        }
        return this.isEnabled && this.isActivated;
    }

    isBgModeEnabled() {
        if (this.useAndroidForegroundService && GeneralCache.os === EOS.android) {
            return this.backgroundModeAndroidProvider.isBgModeEnabled();
        }
        return this.isEnabled;
    }

    /**
     * enable 
     * e.g. while on the map
     */
    async enable() {
        console.log("enable background mode");
        if (this.useAndroidForegroundService && GeneralCache.os === EOS.android) {
            await this.backgroundModeAndroidProvider.enable();
            this.isEnabled = true;
            return;
        }
        await this.resume();
        this.isEnabled = true;
    }

    /**
     * disable
     * e.g. while not on the map
     */
    async disable() {
        console.log("disable background mode");
        if (this.useAndroidForegroundService && GeneralCache.os === EOS.android) {
            await this.backgroundModeAndroidProvider.disable();
            this.isEnabled = false;
            return;
        }
        if (!this.initialized) {
            console.warn("bg mode not initialized");
            return;
        }
        await this.pause();
        this.isEnabled = false;

    }

    async pause() {
        if (this.useAndroidForegroundService && GeneralCache.os === EOS.android) {
            return this.backgroundModeAndroidProvider.pause();
        }
        try {
            if (!this.initialized) {
                console.warn("bg mode not initialized");
                return;
            }
            console.log("pause (disable) background mode");
            if (this.backgroundMode.isEnabled()) {
                this.backgroundMode.disable();
            } else {
                console.log("already disabled");
            }

            // let isEnabled: boolean = (await BackgroundMode.isEnabled()).enabled;
            // if (isEnabled) {
            //     await BackgroundMode.disable();
            // } else {
            //     console.log("already disabled");
            // }
        } catch (err) {
            console.error(err);
        }
    }

    async resume() {
        if (this.useAndroidForegroundService && GeneralCache.os === EOS.android) {
            return this.backgroundModeAndroidProvider.resume();
        }
        try {
            if (!this.initialized) {
                console.warn("bg mode not initialized");
                return;
            }
            console.log("resume (enable) background mode");
            if (!this.backgroundMode.isEnabled()) {
                this.backgroundMode.enable();
            } else {
                console.log("already enabled");
            }

            // let isEnabled: boolean = (await BackgroundMode.isEnabled()).enabled;
            // if (!isEnabled) {
            //     await BackgroundMode.enable();
            // } else {
            //     console.log("already enabled");
            // }
        } catch (err) {
            console.error(err);
        }
    }

}